import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import './styles.scss';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../components/SimpleLocalize";

export const VinosDetalle = (props) => {

    let camino = '..';
    let poslang = '';
    if (props.pageContext.language === 'en') {
        camino = '../..';
        poslang = "_en";
    }

    const data = useStaticQuery(graphql`
		query {
            allWinesdetalleJson {
                nodes {
                  id
                  titulo
                  varietales {
                    titulo
                    src {
                        publicURL
                      id
                    }
                    data {
                      electroconductividad {
                        src
                        texto
                      }
                      tipo_de_suelo {
                        src
                        texto
                      }
                      ubicacion {
                        src
                        texto
                      }
                    }
                  }
                }
              }
		}
	`);

    const [ubicaciones, setUbicaciones] = useState([]);
    const [ubicacionItemSelected, setUbicacionItemSelected] = useState([]);

    const mostrarData = (i, ind) => {
        let seleccionados = [...ubicacionItemSelected];
        seleccionados.forEach((item) => {
            if (item.i === i) {
                item.varietalesSelected = ind;
                item.itemSelected = 0;
            }
        });
        setUbicacionItemSelected(seleccionados);
        const srcPath = ubicaciones[i].varietal[seleccionados[i].varietalesSelected].ubicacion.src;
        let finalSrcPath = '';
        if (props.pageContext.language === 'en') {
            finalSrcPath = srcPath.replace('../imagenes_vinos', '../../imagenes_vinos').replace('.jpg', '_en.jpg');
        } else {
            finalSrcPath = srcPath;
        }
        document.getElementById('wineback' + i).setAttribute('src', finalSrcPath);
    };

    const seleccionarItem = (i, ind, tipo) => {
        let seleccionados = [...ubicacionItemSelected];
        seleccionados.forEach((item) => {
            if (item.i === i) {
                item.itemSelected = ind;
            }
        });
        setUbicacionItemSelected(seleccionados);
        const srcPath = ubicaciones[i].varietal[seleccionados[i].varietalesSelected][tipo].src;
        let finalSrcPath = '';
        if (props.pageContext.language === 'en') {
            finalSrcPath = srcPath.replace('../imagenes_vinos', '../../imagenes_vinos').replace('.jpg', '_en.jpg');
        } else {
            finalSrcPath = srcPath;
        }
        document.getElementById('wineback' + i).setAttribute('src', finalSrcPath);
    };

    useEffect(() => {
        const ubicacionItemSelectedArray = data.allWinesdetalleJson.nodes.map((wine, i) => {
            let varietalesSelected = 0;
            let itemSelected = 0;
            return { i, varietalesSelected: varietalesSelected, itemSelected: itemSelected };
        });

        const ubicacionesArray = data.allWinesdetalleJson.nodes.map((wine, i) => {
            let varietales = wine.varietales.map((varietal, ind) => {
                return varietal.data;
            });
            return { i, varietal: varietales };
        });
        setUbicaciones(ubicacionesArray);
        setUbicacionItemSelected(ubicacionItemSelectedArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SimpleLocalize {...props}>
            {data.allWinesdetalleJson.nodes.map((wine, i) => (<>
                {wine.id !== 'vino02' && <section key={i} className="py-0 text-center position-relative min-vh-100" id={wine.id}>
                    <div className="bg-repeater">
                        <div className="min-vh-83 content-wrapper container ">
                            <div className="row justify-content-around">
                                <div className="min-vh-100 col-md-6 text-start mt-6">
                                    <h1 className="title-2">{wine.titulo}</h1>
                                    <div className="wine-menu-container d-flex flex-wrap">
                                        {ubicaciones.length > 0 &&
                                            wine.varietales.map((varietal, int) => {
                                                return (<button key={int} className={ubicacionItemSelected[i].varietalesSelected === int ? 'active' : null} onClick={() => { mostrarData(i, int) }}>{varietal.titulo}</button>);
                                            })
                                        }
                                    </div>
                                    {ubicaciones.length > 0 && (
                                        <div className="wine-info pt-5 col-md-8">
                                            <div className="ubicacion">
                                                <h3 onClick={() => { seleccionarItem(i, 0, 'ubicacion') }} className={ubicacionItemSelected[i].itemSelected === 0 ? 'active' : null}>
                                                    <FormattedMessage id="ubicacion-en-el-viniedo" defaultMessage="ubicacion-en-el-viniedo" />
                                                </h3>
                                                <p>{ubicaciones[i].varietal[ubicacionItemSelected[i].varietalesSelected].ubicacion.texto}</p>
                                            </div>
                                            <div className="tipo-suelo">
                                                <h3 onClick={() => { seleccionarItem(i, 1, 'tipo_de_suelo') }} className={ubicacionItemSelected[i].itemSelected === 1 ? 'active' : null}>
                                                    <FormattedMessage id="tipo-de-suelo" defaultMessage="tipo-de-suelo" />
                                                </h3>
                                                <p>{ubicaciones[i].varietal[ubicacionItemSelected[i].varietalesSelected].tipo_de_suelo.texto}</p>
                                            </div>
                                            <div className="electroconductividad">
                                                <h3 onClick={() => { seleccionarItem(i, 2, 'electroconductividad') }} className={ubicacionItemSelected[i].itemSelected === 2 ? 'active' : null}>
                                                    <FormattedMessage id="electroconductividad-del-cuartel" defaultMessage="electroconductividad-del-cuartel" />
                                                </h3>
                                                <p>{ubicaciones[i].varietal[ubicacionItemSelected[i].varietalesSelected].electroconductividad.texto}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="min-vh-100 col-md-6 mt-6">
                                    {ubicaciones.length > 0 &&
                                        wine.varietales.map((varietal, int) => {
                                            const wineShowHide = ubicacionItemSelected[i].varietalesSelected === int ? 'd-block' : 'd-none';
                                            return (<div key={int} className={'wine-bottle ' + wineShowHide}><img src={varietal.src.publicURL} /></div>);
                                        })
                                    }
                                    {
                                        ubicaciones.length > 0 && (() => {
                                            const srcPath = ubicaciones[i].varietal[ubicacionItemSelected[i].varietalesSelected].ubicacion.src;
                                            let finalSrcPath = '';
                                            if (props.pageContext.language === 'en') {
                                                finalSrcPath = srcPath.replace('../imagenes_vinos', '../../imagenes_vinos').replace('.jpg', '_en.jpg');
                                            } else {
                                                finalSrcPath = srcPath;
                                            }
                                            return (<img id={'wineback' + i} src={finalSrcPath} alt={wine.titulo} className="img-fluid image-wine-back" />);
                                        })()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
                </>
            ))}
        </SimpleLocalize>
    );

};
