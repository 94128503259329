import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const WinesMenu = () => {
	const data = useStaticQuery(graphql`
		query {
			allWinesmenuJson {
				nodes {
					name
					pathId
					src {
							id
							publicURL
						}
				}
			}
		}
	`)

	return (
		<div className="row justify-content-md-center vinos-menu">
			{data.allWinesmenuJson.nodes.map((wine,i) => (
				<div className="col-md-2 item-vinos" key={i}>
					<div className="border border-warning h-100">
						<a href={'#'+wine.pathId}><img src={wine.src.publicURL} alt={wine.name} /></a>
						<p className="text-center"><strong>{wine.name}</strong></p>
					</div>
				</div>
			))}
		</div>
	)
}