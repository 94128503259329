import React from "react"
import Layout from "../components/layout"
import img01 from "../images/vinos-01.jpg"
import { WinesMenu } from "../components/vinos/winesMenu"
import { VinosDetalle } from "../components/vinos/VinosDetalle"
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../components/SimpleLocalize";

const IndexPage = (props) => (
	<SimpleLocalize {...props}>
	<Layout {...props}>
		<section className="py-0 text-center position-relative min-vh-100">
			<div className="bg-holder header-video" style={{ backgroundImage: "url(" + img01 + ")" }}>
				<div className="video-wrapper position-absolute w-100 h-100">
					<iframe
						title="video introduction"
						frameBorder="0"
						height="100%"
						width="100%"
						src="https://youtube.com/embed/M96Jx8NOaGk?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=M96Jx8NOaGk&modestbranding=1"
					>
					</iframe>
				</div>
				<div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
					<div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "left": "0", "zIndex": "1" }}></div>
						<h1 style={{ "zIndex": "2" }}><FormattedMessage id="vinos-title" defaultMessage="vinos-title" /></h1>
						<p style={{ "zIndex": "2" }} className="col-8 offset-2 text-center">
							<FormattedMessage id="el-terroir-que-envuelve" defaultMessage="el-terroir-que-envuelve" />.
						</p>
				</div>
			</div>
		</section>
		<section className="py-0 text-center position-relative min-vh-100">
			<div className="bg-holder" style={{ background: "#EEEEEE" }}>
				<div className="container min-vh-100 content-wrapper  d-flex flex-column justify-content-center ">
					<WinesMenu {...props} />
				</div>
			</div>
		</section>
		<VinosDetalle {...props}/>
	</Layout>
	</SimpleLocalize>
)

export default IndexPage